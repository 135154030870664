<template>
  <div>
    <page-title :heading="$t('accounting.lang_proFormaInvoice')" :subheading="$t('accounting.lang_proFormaInvoice')" :icon=icon ></page-title>

    <div class="app-main__inner">
      <ProformatsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import ProformatsComponent from "@/components/billing/proformat/ProformatsComponent";


export default {
  name: "Proformats",
  components: {ProformatsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
